<template>
  <Content :filter="true" :showFilterDefault="true">

    <template v-slot:filter>
      <div class="flex flex-wrap items-center">
        <div class="flex flex-wrap sm:flex-nowrap items-center mr-3  ">
          <div class="w-48">
            <Input type="date" v-model="filter.from" @change="getItems"/>
          </div>
          <span class="p-1">-</span>
          <div class="w-48">
            <Input type="date" v-model="filter.to" @change="getItems"/>
          </div>
        </div>
        <div class="w-64 mr-3">
          <Select v-model="filter.selectedSupplier" :items="suppliers" placeholder="Piegādātājs" @click="getItems" :clearAble="true" :showPlaceholder="false" />
        </div>

        <button type="button"
                @click="getReportExcel"
                class="inline-flex items-center px-2.5 py-1.5 border border-gray-300 dark:border-gray-500 shadow-sm text-xs font-medium rounded text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-750 hover:bg-gray-50 dark:hover:bg-gray-770 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 my-auto mr-2">
          Drukāt Excel
        </button>

      </div>
    </template>

    <template v-slot:content>
      <template v-if="catalogItemUnits && catalogItemUnits.data.length > 0">
        <div class="sm:rounded-md mb-3">
          <ul role="list" class="">
            <template v-for="(item, index) in catalogItemUnits.data" :key="index">
              <CatalogItemUnitListItem :item="item" />
            </template>
          </ul>
        </div>

        <Pagination :meta="catalogItemUnits.meta" :onPageChange="onPageChange" />
      </template>

      <template v-else>
          <p class="py-6 text-center dark:text-gray-300">
            Diemžēl nekas netika atrasts
          </p>
      </template>
    </template>
  </Content>
</template>

<script>
import { debounce } from 'vue-debounce'
import {mapGetters} from "vuex";
import Search from "@/services/Helpers/search";
import { defineAsyncComponent } from 'vue'
const CatalogItemUnitListItem = defineAsyncComponent(() =>
  import('@/components/CatalogItemUnits/CatalogItemUnitListItem'))
import Pagination from "@/components/Components/Pagination";
import Input from "@/components/Components/Input"
import Select from "@/components/Components/Select"
import Dates from "@/services/Helpers/dates";
import axios from "axios";

export default {
    name: "CatalogItemUnits",
    components: {
        CatalogItemUnitListItem,
        Pagination,
        Input,
        Select
    },
    data: () => ({
      filter: {
        from: '',
        to: '',
        selectedSupplier: null
      },
      queryParams: null
    }),
    created() {
      this.$store.dispatch("setDataLoading", true);
      this.$store.dispatch('resetSearch');

      if (!this.suppliers) {
        this.$store.dispatch('fetchSuppliers');
      }
      // Get query params from URL
      this.queryParams = this.$route.query;
      // If there are no URL params set default params
      if (Object.keys(this.queryParams).length === 0) {
        this.$router.replace({
          query: {
            from: this.filter.from,
            to: this.filter.to,
            page: Search.getCurrentPage(),
            supplier_id: this.filter.selectedSupplier ? this.filter.selectedSupplier.id : '',
            q: this.search
          }
        })
      }

      // Update component data from query prams
      this.setFiltersFromParams()
      this.searchUnits()
    },
    mounted() {
        this.$store.dispatch('removeAllFormsForDisplay');
        this.$store.dispatch('resetSearch');
    },
    computed: {
        ...mapGetters({
            catalogItemUnits: 'catalogItemUnits',
            search: 'search',
            suppliers: 'fetchedSuppliers'
        })
    },
    watch: {
        search: function () {
            this.searchUnits()
        }
    },
    methods: {
      getItems() {
        this.$router.replace({
          query: {
            from: this.filter.from,
            to: this.filter.to,
            supplier_id: this.filter.selectedSupplier ? this.filter.selectedSupplier.id : '',
            page: 1,
          }
        })

        this.queryParams = this.$route.query
        this.searchUnits()
      },
      setFiltersFromParams() {
        this.filter.from = this.queryParams.from ? this.queryParams.from : this.filter.from;
        this.filter.to = this.queryParams.to ? this.queryParams.to : this.filter.to;
        if (this.suppliers) {
          this.filter.selectedSupplier = this.suppliers.find(supplier => {
            return supplier.id == this.queryParams.supplier_id;
          })
        }
      },
      searchUnits: debounce(function () {
          this.$store.dispatch('getCatalogItemUnits', this.queryParams)
      }, 500),
      onPageChange(page) {
        this.searchUnits();
      },

      getReportExcel() {
        this.$store.dispatch('getCatalotItemUnitReportExcel', {
          from: this.filter.from,
          to: this.filter.to,
          supplier_id: this.filter.selectedSupplier ? this.filter.selectedSupplier.id : '',
        })
      },
    }
}
</script>